export const EXTERNAL_LINKS = {
  corepass_app_store: process.env.GATSBY_COREPASS_APP_STORE_URL,
  corepass_google_play: process.env.GATSBY_COREPASS_GOOGLE_PLAY_URL,
  ping_academy: "https://pingexplore.com/blog-categories/academy",
  ping_api_documentation: "https://docs.ping.exchange/docs/api",
  ping_app: process.env.GATSBY_PING_EXCHANGE_APP_URL,
  ping_assetsList: `${process.env.GATSBY_PING_EXCHANGE_API_URL}/frontoffice/api/assets-info`,
  ping_blog_tutorial: "https://pingexplore.com/blog-categories/tutorials",
  ping_blog: "https://pingexplore.com/blog-categories/blog",
  ping_fee: `${process.env.GATSBY_PING_EXCHANGE_APP_URL}/fees`,
  ping_help: "/help-center",
  ping_home: "/",
  ping_markets: `${process.env.GATSBY_PING_EXCHANGE_APP_URL}/markets`,
  ping_portfolio: process.env.GATSBY_PING_EXCHANGE_PORTFOLIO_URL,
  ping_status: "https://status.ping.exchange/",
  ping_referral: process.env.GATSBY_PING_EXCHANGE_REFERRAL_URL,
  ping_verification_center: "/verification-center",
};

export const SOCIAL_LINKS = {
  blockchain_hub: "https://github.com/bchainhub",
  discord: "https://discord.gg/8sjUWrERdX",
  facebook: "https://www.facebook.com/PingExchange",
  github: "https://github.com/PingExchange",
  instagram: "https://www.instagram.com/pingexchange/",
  linkedin: "https://www.linkedin.com/company/pingexchange",
  telegram: "https://t.me/PingHex",
  twitter: "https://x.com/PingHEX",
  youtube: "https://www.youtube.com/@pingexchange",
};

export const SERVICES = {
  hcaptcha: {
    sitekey: process.env.GATSBY_HCAPTCHA_SITE_KEY,
  },
};
